import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';

if ((window as any).ENV.production && (window as any).ENV.sentry_dsn) {
  import('~/utils/monitoring.client').then(({ init }) => init());
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
